// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-tags-index-js": () => import("./../../../src/pages/en/tags/index.js" /* webpackChunkName: "component---src-pages-en-tags-index-js" */),
  "component---src-pages-no-tags-index-js": () => import("./../../../src/pages/no/tags/index.js" /* webpackChunkName: "component---src-pages-no-tags-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-stillinger-page-js": () => import("./../../../src/templates/stillinger-page.js" /* webpackChunkName: "component---src-templates-stillinger-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-tjenester-page-js": () => import("./../../../src/templates/tjenester-page.js" /* webpackChunkName: "component---src-templates-tjenester-page-js" */)
}

